import React from "react";
import Login from "components/base/userLogin/login";

function LoginContainer() {
  return (
    <>
      <Login />
    </>
  );
}

export default LoginContainer;
