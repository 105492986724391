import PostEditor from "components/editor/postEditor";
import React from "react";

function EditorContainer() {
  return (
    <>
      <PostEditor />
    </>
  );
}

export default EditorContainer;
