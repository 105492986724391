import React, { useState } from "react";

import "./Comment.scss";
import { Timeline } from "flowbite-react";
import { timelineCustomTheme } from "styles/theme/timeline";
import CommentEditor from "components/editor/commentEditor";
import { useRecoilValue } from "recoil";
import { themeAtom } from "recoil/themeAtom";
import { authAtom } from "recoil/authAtom";
import { postAtom } from "recoil/postAtom";
import { commentAtom } from "recoil/commentAtom";
import Comments from "./comments/Comments";
import {
  isWriteComment,
  uploadCommentHandler,
} from "./comments/CommentsHandler";

function Comment({ ...props }) {
  const { commentRef, comments } = props;
  const authDto = useRecoilValue(authAtom);
  const isDark = useRecoilValue(themeAtom);
  const [, setUpdater] = useState(0);
  const [reply, setReply] = useState({
    flag: false, // true: 답글 작성 false: 편집
    editId: null, // 편집 중인 댓글 id
    target: null, // 답글 대상
    content: "",
    private: false,
    files: [],
  });
  const postContent = useRecoilValue(postAtom);
  const commentState = useRecoilValue(commentAtom);

  const [editorComment, setEditorComment] = useState({
    content: "",
    files: [],
    private: false,
  });

  return (
    <div
      ref={commentRef}
      className="comment-container"
      data-color-mode={isDark ? "dark" : "light"}
    >
      <Timeline className="comments" theme={timelineCustomTheme}>
        <Comments
          comments={comments}
          reply={reply}
          setReply={setReply}
          setUpdater={setUpdater}
        />
      </Timeline>
      {/* Post Comment Editor */}
      {isWriteComment({ commentState: commentState, authDto: authDto }) ? (
        <CommentEditor
          comment={editorComment}
          setComment={setEditorComment}
          onSave={() =>
            uploadCommentHandler({
              postContent: postContent,
              comments: comments,
              editorComment: editorComment,
              setEditorComment: setEditorComment,
            })
          }
        />
      ) : null}
    </div>
  );
}

export default Comment;
