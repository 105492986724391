import * as React from "react";

const PasswordIcon = (props) => (
  <svg
    id="_x32_"
    xmlns="http://www.w3.org/2000/svg"
    width={800}
    height={800}
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    {...props}
  >
    <style>{"\n    .st0{fill:current}\n  "}</style>
    <path
      className="st0"
      d="M461.344 197.344c-15.813-15.875-37.984-25.781-62.156-25.75h-8.953v-37.375c.016-36.969-15.078-70.719-39.328-94.906C326.703 15.063 292.969-.016 256 0c-36.969-.016-70.719 15.063-94.922 39.313-24.234 24.187-39.328 57.937-39.312 94.906v37.375h-8.969c-24.172-.031-46.328 9.875-62.141 25.75-15.859 15.813-25.766 37.969-25.75 62.156v164.594c-.016 24.188 9.891 46.344 25.75 62.156A87.68 87.68 0 0 0 112.797 512h286.391a87.7 87.7 0 0 0 62.156-25.75c15.859-15.813 25.766-37.969 25.75-62.156V259.5c.015-24.187-9.891-46.344-25.75-62.156m-281.453-63.125c.016-21.094 8.469-39.938 22.297-53.813C216.047 66.594 234.891 58.125 256 58.125s39.953 8.469 53.813 22.281c13.828 13.875 22.281 32.719 22.297 53.813v37.375H179.891zM420.25 445.156c-5.484 5.438-12.75 8.719-21.063 8.719h-286.39c-8.313 0-15.563-3.281-21.047-8.719-5.438-5.469-8.703-12.75-8.719-21.063V259.5c.016-8.328 3.281-15.594 8.719-21.063 5.484-5.438 12.734-8.719 21.047-8.719h286.391c8.313 0 15.578 3.281 21.063 8.719 5.422 5.469 8.703 12.734 8.719 21.063v164.594c-.017 8.312-3.298 15.594-8.72 21.062"
    />
    <path
      className="st0"
      d="M255.984 279.969c-23.109 0-41.844 18.75-41.844 41.875 0 17.031 10.219 31.625 24.828 38.156l-9.25 60.094h52.563L273.016 360c14.609-6.531 24.844-21.125 24.844-38.156-.001-23.125-18.751-41.875-41.876-41.875"
    />
  </svg>
);
export default PasswordIcon;
